<template>
  <v-navigation-drawer app v-model="drawer" dark width="230">
    <v-divider class="mb-1" />
    <v-list dense nav elevation="5" color="drawerColorSecondary">
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <!--Imagen de perfil-->
          <v-icon>$custom</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <!--Datos de usuario-->
          <v-list-item-title class="display-1" v-text="cuenta.nombre + ' ' + cuenta.apellidos" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider class="mb-2" />

    <v-list expand nav>
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item"></base-item-group>
        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
      <div />
     
    </v-list>
    <template v-slot:append>
      <v-list>
        <v-list-item class="b_cerrar">
          <v-list-item-content>
            <v-list-item-title @click="logout" v-text="$t('salir')"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState, mapMutations } from "vuex";
export default {
  name: "AdministradorCoreDrawer",
  data: () => ({
    items: [
      {
        title: "SolicitudesPrestamosAdmin",
        to: { name: "SolicitudesPrestamosAdmin" },
      },
      {
        title: "PaquetesPublicosAdmin",
        to: { name: "PaquetesPublicosAdmin" },
      },
      {
        title: "DevolucionesPrestamosAdmin",
        to: { name: "DevolucionesPrestamosAdmin" },
      },
      {
        title: "Historial",
        to: { name: "Historial" },
      },
      {
        title: "administrar",
        to: { name: "carreraAdmin" },
        children: [
          // {
          //   title: "carreraAdmin",
          //   to: { name: "carreraAdmin" },
          // },
          {
            title: "MaterialesAdmin",
            to: { name: "MaterialesAdmin" },
          },
          {
            title: "cuentasAdmin",
            to: { name: "cuentasAdmin" },
          },
          {
            title: "tutoresAdmin",
            to: { name: "tutoresAdmin" },
          },
        ],
      },
    ],
  }),
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["barColor", "barImage", "cuenta"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },
  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    logout: function () {
      this.$store.dispatch("logout");
      this.$router.push("/SPML/login");
      const w = window.open('https://accounts.google.com/Logout');        
    },
  },
};
</script>
<style>
.a__nav {
  color: #20419a;
  font-size: 15px;
  margin: 0 0.3vw;
  padding: 2px;
  border: 0px solid blue;
  border-color: black;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.a__nav:hover {
  box-shadow: 5px 10px 18px black;
}
.b_cerrar {
  margin: 0.5vw;
  padding-left: 15px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  background-color: rgba(220, 25, 25, 0.719);
}
</style>
